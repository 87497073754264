//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Imports */
import { Row, Col, Input, Form, Button, Typography } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** React OTP Imports */
import OtpInput from "react-otp-input";
//** Components Imports */
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
import Footer from "Common/Layouts/Footer";
import Background from "Common/Layouts/AuthColOne";
import { useTranslation } from "react-i18next";
import {
  buttonText,
  formError,
  formLabel,
  messageText,
  MiscellaneousText,
} from "utility/constants";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

/**
 * @function base component
 * @param {*} props
 */

function ConfirmPassword(props) {
  //** Assigning Hooks  */
  const history = useHistory();
  //** Destructing Hooks */
  const { t } = useTranslation();

  //** Destructing Ant Components */
  const { Title, Text } = Typography;

  //** Destructing Constants */
  const { New_Confirmation_Code_Message } = messageText;
  const { Confirm_Email_Text, Enter_Code_Text } = MiscellaneousText;
  const { isCodeEmpty } = formError;
  const { New_Password_Text, New_Password_Label } = formLabel;
  const { Confirm_Email_Btn, Resend_Code_Btn } = buttonText;

  //** States for handling hooks */
  const [otp, setOtp] = useState();
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userName : ""
  );
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  //** Use Effect for checking if email exists or not */
  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/reset-password") &&
        window.location.href.includes("email=")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        form.setFieldsValue({
          code: Data.code,
          password: "",
        });
        setemail(window.location.href.split("email=")[1]);
      } else {
        history.push("/");
      }
    }
  }, [form, history, props.location.state]);

  /**
   * Handle reset code from AWS Cognito
   */
  const resendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email).then(() => {
        setApiResultModal(() => ({
          message: t(New_Confirmation_Code_Message),
          isApiResultModelOpen: true,
          type: "success",
        }));
        setloading(false);
      });
    } catch (error) {
      console.log("error resend code :", error.message);
      setloading(false);
      setApiResultModal(() => ({
        message: error?.message,
        isApiResultModelOpen: true,
        type: "failure",
      }));
    }
  };

  //** Confirm email and otp using new password */
  const confirmReset = async (e) => {
    setloading(true);

    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        history.push({
          pathname: "/signin",
          state: { feedback: "Signin with new password.", userName: "" },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      setloading(false);
      setApiResultModal(() => ({
        message: error?.message,
        isApiResultModelOpen: true,
        type: "failure",
      }));
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <>
      <Row justify="center">
        <Background />
        <Col span={12} md={12} lg={12} xs={24}>
          <Row gutter={24} justify="center" className="login-module-col-2">
            <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
              <LoginModuleLogo />
              <Form
                layout="vertical"
                name="normal_signup"
                className="signup-form"
                requiredMark={false}
                onFinish={(e) => confirmReset(e)}
                form={form}
              >
                <Title className="fs-18 text-center " level={5}>
                  {t(Confirm_Email_Text)}
                </Title>

                <Text className="confirm-email-paragraph">
                  {t(Enter_Code_Text)}
                </Text>

                <Form.Item
                  className="otp"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: t(isCodeEmpty),
                    },
                  ]}
                >
                  <OtpInput
                    name="code"
                    id="code"
                    key="code"
                    shouldAutoFocus
                    className="otp-input"
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    //Dev Comment :- isInputSecure={true}
                  />
                </Form.Item>

                <Form.Item
                  label={t(New_Password_Text)}
                  name="password"
                  className="password-container font-bold"
                >
                  <Input.Password
                    placeholder={t(New_Password_Label)}
                    className="border-light-color login-module-input"
                  />
                </Form.Item>

                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={8}>
                      <Button
                        type="link"
                        onClick={resendConfirmationCode}
                        className="login-btn fs-18 font-bold"
                      >
                        {t(Resend_Code_Btn)}
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        size="large"
                        disabled={loading}
                        htmlType="submit"
                        block
                        tabIndex={5}
                        onClick={() => history.push("/reset-password")}
                        type="primary"
                        className="w-100"
                      >
                        {t(Confirm_Email_Btn)}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}

export default ConfirmPassword;
