//** React Imports */
import React, { useState } from "react";
//** Ant Design Imports */
import { Button, Typography, Col, Image, Row, Card } from "antd";
//** Router Imports */
import { useHistory } from "react-router-dom";
//** Images Imports */
import { Images } from "assets/images/Images";
import InviteModal from "Common/Components/InviteModal/InviteModal";
//** Redux Action Imports */
import { setCurrentRenderKey } from "store/actions/settingAction";
//** Constant Imports */
import { useTranslation } from "react-i18next";
//** Redux Imports */
import { useDispatch, useSelector } from "react-redux";
//** Constant Imports */
import { buttonText, homeText, MiscellaneousText } from "utility/constants";
//** Style Imports */
import "./Home.less";
import Title from "Common/Components/CustomAntD/Title";
import { Permission, usePermissions } from "../../hooks/usePermissions";

/**
 * Home Component
 * @return {ReactComponent}
 */
const Home = () => {
  //** Destructing Hooks */
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { can } = usePermissions();

  //** Destructing Images */
  const { InviteIconImg, ManagePlanImg, HelpfullTipsImg, ConfigureImg } =
    Images.Home;

  //** Destructing Constants */
  const { Invite_Team_Title, Configure_Text, Help_Text, Manage_Plan } =
    buttonText;
  const {
    DS_Configuration_Text,
    Learn_3p_Text,
    Welcome_Home_Text_P1,
    Welcome_Home_Text_P2,
  } = homeText;
  const { Hello } = MiscellaneousText;

  //** Invite Modal Open  */
  const [isInviteModelOpen, setIsInviteModelOpen] = useState(false);

  const history = useHistory();

  //** Handles Navigation */
  const homeNavigationHandler = (type) => {
    switch (type) {
      case "manage":
        history.push("/school-settings");
        dispatch(setCurrentRenderKey("billing"));
        break;
      default:
        break;
    }
  };

  //** Given Name of User from Local Storage For re-rendering name  */
  // P.S If can be fixed using useEffect re-render
  const name = useSelector((state) => state?.loginReducer?.given_name);

  const cards = [
    {
      title: t(Learn_3p_Text),
      btnOnclick: () => history.push("/help"),
      btnTitle: t(Help_Text),
      img: HelpfullTipsImg,
    },
  ];

  if (can(Permission.ConfigureDataSources)) {
    cards.push({
      title: t(DS_Configuration_Text),
      btnOnclick: () => history.push("/configure-data"),
      btnTitle: t(Configure_Text),
      img: ConfigureImg,
    });
  }
  if (can(Permission.ManagePaymentMethod)) {
    cards.push({
      title: "Manage your billing plan",
      btnOnclick: () => homeNavigationHandler("manage"),
      btnTitle: t(Manage_Plan),
      img: ManagePlanImg,
    });
  }
  if (can(Permission.InviteUsers)) {
    cards.push({
      title: "Collaboration with your Team",
      btnOnclick: () => setIsInviteModelOpen(true),
      btnTitle: t(Invite_Team_Title),
      img: InviteIconImg,
    });
  }

  return (
    <>
      <InviteModal
        isInviteModelOpen={isInviteModelOpen}
        setIsInviteModelOpen={setIsInviteModelOpen}
      />
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row className="home-wrapper flex-col">
            <div className="flex-col home-container">
              <Title level={2}>
                {t(Hello)} {name}!
              </Title>
              <Typography.Text className="home-text">
                {t(Welcome_Home_Text_P1)} <br />
                {t(Welcome_Home_Text_P2)}
              </Typography.Text>
            </div>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="start" gutter={[12, 0]}>
            {cards.map(({ title, img, btnTitle, btnOnclick }) => (
              <Col key={title} span={6}>
                <div className="home-card-wrapper">
                  <Row
                    justify="center"
                    gutter={[0, 32]}
                    className="h-100 flex-col justify-content-between"
                  >
                    <Card className="w-100 d-flex align-center justify-content-center">
                      <Image
                        preview={false}
                        src={img}
                        style={{ height: "20vh" }}
                      />
                    </Card>
                    <Typography.Title level={4} className="mb-0 text-center ">
                      {title}
                    </Typography.Title>
                    <Button type="primary" onClick={btnOnclick} block>
                      {btnTitle}
                    </Button>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Home;
