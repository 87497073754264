import React, { useState, useEffect } from "react";
import { RingProgress } from "@ant-design/plots";

const RefreshRecurringInterval = ({ data }) => {
  const initialconfig = {
    height: 250,
    width: 200,
    autoFit: false,
    percent: 0,
    color: ["#FFC90A", "#FFF3CA"],
  };

  const [config, setConfig] = useState(initialconfig);

  useEffect(() => {
    setConfig((pS) => {
      const { successCount, failureCount } = data;

      pS.percent = successCount / (successCount + failureCount);
      return { ...pS };
    });
  }, [data]);

  return <RingProgress {...config} />;
};

export default RefreshRecurringInterval;
