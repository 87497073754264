//** React Imports */
import React, { useEffect, useState } from "react";
//** Ant Design Imports */
import { Button, Col, Form, Input, Row, message } from "antd";
//** Amplify Imports */
import { API, Auth } from "aws-amplify";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Constants Imports */
import {
  buttonText,
  commonLinks,
  emailPattern,
  formError,
  formLabel,
} from "utility/constants";
//** Layout Imports */
import Footer from "Common/Layouts/Footer";
import Background from "Common/Layouts/AuthColOne";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
//** For new password creating */
function CreatePassword(props) {
  const { t } = useTranslation();

  const {
    isFirstNameEmpty,
    isFieldInvalid,
    isEmailEmpty,
    isEmailInvalid,
    isPasswordEmpty,
    isPasswordMin,
  } = formError;

  const {
    firstName,
    First_Name_Placeholder,
    lastName,
    isLastNameEmpty,
    Last_Name_Placeholder,
    Email_Label,
    emailText,
    Create_Password_Label,
    Password_Label,
    Privacy_Policy_P1,
    Privacy_Policy_P2,
  } = formLabel;

  const { Sign_Up_Text, Login_Text } = buttonText;

  const { Terms_Conditions_Link } = commonLinks;

  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state?.userAttributes?.email || ""
  );
  const history = useHistory();

  useEffect(() => {
    if (props.location?.state) {
      let email = props.location?.state?.userAttributes?.email;
      if (email) {
        setemail(email);
        form.setFieldsValue({
          email: email,
        });
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }, [props, form, history]);

  const handleUpdateProfile = async () => {
    setloading(true);
    let formvalue = form.getFieldValue();
    let data = {
      email: formvalue.email,
      family_name: formvalue.family_name,
      given_name: formvalue.given_name,
    };
    let init = {
      body: {
        ...data,
      },
    };

    API.post("schoolBIREST", `/user/update`, init)
      // .then(() => {
      // message.success("Profile Updated");
      // })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setloading(false));
  };

  const confirmChangePassword = (values) => {
    setloading(true);
    let oldPassword = props.location?.state?.oldPassword;
    if (oldPassword) {
      let newPassword = form.getFieldValue("password");
      Auth.signIn(email, oldPassword).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newPassword, [])
            .then(async () => {
              delete values.password;
              await handleUpdateProfile(values);
              message.success("Login Successfull.");
              history.push("/");
            })
            .catch((err) => {
              console.log(err);
              message.error(err);
            });
        }
      });
    }
  };

  return (
    <Row justify="center">
      <Background />
      <Col span={12} md={12} lg={12} xs={24}>
        <Row
          gutter={24}
          justify="center"
          align="middle"
          className="login-module-col-2"
        >
          <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
            <LoginModuleLogo />

            <Form
              requiredMark={false}
              layout="vertical"
              name="normal_reset"
              onFinish={(e) => confirmChangePassword(e)}
              autoComplete="off"
              form={form}
            >
              <Row justify="space-between" gutter={[10, 10]}>
                <Col span={12}>
                  <Form.Item
                    label={t(firstName)}
                    className="font-bold"
                    name="given_name"
                    rules={[
                      {
                        required: true,
                        message: t(isFirstNameEmpty),
                      },
                      {
                        pattern: /^[a-zA-Z]+$/,
                        message: t(isFieldInvalid),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(First_Name_Placeholder)}
                      disabled={loading}
                      id="firstName"
                      key="firstName"
                      name="firstName"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t(lastName)}
                    name="family_name"
                    className="font-bold"
                    rules={[
                      {
                        required: true,
                        message: t(isLastNameEmpty),
                      },
                      {
                        pattern: /^[a-zA-Z]+$/,
                        message: t(isFieldInvalid),
                      },
                    ]}
                  >
                    <Input
                      id="lastName"
                      key="lastName"
                      name="lastName"
                      placeholder={t(Last_Name_Placeholder)}
                      disabled={loading}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={t(Email_Label)}
                name="email"
                className="font-bold"
                rules={[
                  { required: true, message: t(isEmailEmpty) },
                  {
                    pattern: emailPattern,
                    message: t(isEmailInvalid),
                  },
                ]}
              >
                <Input
                  disabled
                  autoFocus
                  value={email}
                  id="userName"
                  key="userName"
                  name="userName"
                  placeholder={t(emailText)}
                />
              </Form.Item>
              <Form.Item
                label={t(Create_Password_Label)}
                className="font-bold"
                name="password"
                rules={[
                  {
                    min: 8,
                    message: t(isPasswordMin),
                  },
                  { required: true, message: t(isPasswordEmpty) },
                ]}
              >
                <Input.Password
                  id="password"
                  key="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder={t(Password_Label)}
                  disabled={loading}
                />
              </Form.Item>

              <Form.Item>
                <Typography.Text className="fs-14 pb-4">
                  {t(Privacy_Policy_P1)}
                </Typography.Text>
                <Typography.Link
                  href={Terms_Conditions_Link}
                  target="_blank"
                  rel="noreferrer"
                  className="fs-14 font-bold"
                >
                  &nbsp; {t(Privacy_Policy_P2)}
                </Typography.Link>
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle" gutter={32}>
                  {/* <Col span={12}>
                    <Button
                      size="large"
                      ghost
                      block
                      type="primary"
                      onClick={() => history.push("/signin")}
                      disabled={loading}
                    >
                      {t(Login_Text)}
                    </Button>
                  </Col> */}
                  <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      type="primary"
                      className="w-100"
                      loading={loading}
                    >
                      {t(Sign_Up_Text)}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
}

export default CreatePassword;
