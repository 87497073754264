import { Col, Row, Tabs, Tag, Typography, Switch, Space, Tooltip } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NotificationSDK from "sdk/NotificationsSDK";
import { formLabel, notificationModule } from "utility/constants";
import Loader from "../Loader/Loader";

const NotificaitionContent = () => {
  const { Title, Text } = Typography;

  const { email } = formLabel;
  const { In_App_Text, Notification_Title, Notification_Subtitle } =
    notificationModule;
  const { t } = useTranslation();
  const [allnotifications, setAllNotifications] = useState({});
  const [loader, setLoader] = useState(false);

  const { userId } = useSelector((state) => state.loginReducer); // Getting group from login reducer

  const fetchNotificationsTypes = useCallback(async () => {
    setLoader(true);

    const notificationsList = await NotificationSDK?.listNotificationsType();

    const preferedNotificationInput = {
      user: userId,
    };

    const userPreferedNotifications =
      await NotificationSDK.getPreferedNotifications(preferedNotificationInput);

    const notificationsData = notificationsList?.map((notification) => {
      const hasUserPreferedNotification = userPreferedNotifications.find(
        (userPreferedNotification) =>
          userPreferedNotification.notificationType === notification.id
      );

      if (hasUserPreferedNotification) {
        return {
          ...notification,
          emailNotification:
            hasUserPreferedNotification.emailNotification || false,
          inAppNotification:
            hasUserPreferedNotification.inAppNotification || false,
          webPushNotification:
            hasUserPreferedNotification.webPushNotification || false,
          hasCreate: true,
          updateNotificationsPreferenceId: hasUserPreferedNotification.id,
        };
      } else {
        return {
          ...notification,
          emailNotification: false,
          inAppNotification: false,
          webPushNotification: false,
          hasCreate: false,
        };
      }
    });

    const output = {};

    for (const item of notificationsData) {
      const [mainType, subType] = item.notification_type.split("__");

      if (subType) {
        if (!output[mainType]) {
          output[mainType] = {};
        }
        if (!output[mainType][subType]) {
          output[mainType][subType] = [];
        }

        output[mainType][subType].push(item);
      } else {
        if (!output[mainType]) {
          output[mainType] = [];
        }

        output[mainType].push(item);
      }
    }

    setAllNotifications(output);
    setLoader(false);
  }, [userId]);

  useEffect(() => {
    fetchNotificationsTypes();
  }, [fetchNotificationsTypes]);

  const updateNotificationsPreference = async (
    notificationsPreferenceVariables
  ) => {
    setLoader(true);

    const { item, type } = notificationsPreferenceVariables;

    const notificationsPreferenceInput = {
      user: userId,
      // userDetails: JSON.stringify(acct?.userDetails),
      ...type,
    };

    if (item?.hasCreate) {
      await NotificationSDK?.updateUserNotificationPreferenceForUser({
        ...notificationsPreferenceInput,
        id: item?.updateNotificationsPreferenceId,
      });
    } else {
      await NotificationSDK?.createUserNotificationPreferenceForUser({
        ...notificationsPreferenceInput,
        notificationType: item?.id,
      });
    }

    await fetchNotificationsTypes();
    setLoader(false);
  };

  const GetNotificationHandler = ({
    notificationType,
    notificationSubType,
  }) => {
    const currentNotifications = notificationSubType
      ? allnotifications?.[notificationType]?.[notificationSubType]
      : allnotifications?.[notificationType];

    return (
      <Space direction="vertical" size="large" className="w-100">
        <Row>
          <Col span={4} className="d-flex justify-content-center">
            <Tag className="notification-tag" color="#FFF3C7">
              {t(email)}
            </Tag>
          </Col>
          <Col span={4} className="d-flex justify-content-center">
            <Tag className="notification-tag" color="#FFF3C7">
              {t(In_App_Text)}
            </Tag>
          </Col>
          <Col span={16}>
            <Text> Notification Type : </Text>
          </Col>
        </Row>

        {currentNotifications?.map((item) => {
          return (
            <Row>
              <Col span={4} className="d-flex justify-content-center">
                <Tooltip title={"Yet to Come"} color="#F5DB5D">
                  <Switch
                    defaultChecked={item?.emailNotification}
                    onChange={(value) =>
                      updateNotificationsPreference({
                        item,
                        type: { emailNotification: value },
                      })
                    }
                    disabled={true}
                  />
                </Tooltip>
              </Col>
              <Col span={4} className="d-flex justify-content-center">
                <Switch
                  defaultChecked={item?.inAppNotification}
                  onChange={(value) =>
                    updateNotificationsPreference({
                      item,
                      type: { inAppNotification: value },
                    })
                  }
                />
              </Col>
              <Col span={16}>
                <Title level={5} className="notification-type-title">
                  {item?.notification_text}
                </Title>
              </Col>
            </Row>
          );
        })}
      </Space>
    );
  };

  const GetSubNotificationContent = ({ notificationType }) => {
    // TODO:
    const getSubNotificationTabs = allnotifications?.[notificationType];

    if (Array?.isArray(getSubNotificationTabs)) {
      return (
        <GetNotificationHandler
          notificationType={notificationType}
          notificationSubType={false}
        />
      );
    }

    const items = Object.keys(getSubNotificationTabs).map((key) => {
      const label = _.map(_.toLower(key).split("_"), _.capitalize).join(" ");
      return {
        label,
        key,
        children: (
          <GetNotificationHandler
            notificationType={notificationType}
            notificationSubType={key}
          />
        ),
      };
    });

    return (
      <Tabs className="data-sync-tabs">
        {items?.map((item) => {
          return (
            <Tabs.TabPane tab={item.label} key={item.key}>
              {item.children}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    );
  };

  const notificationModalItem = Object.keys(allnotifications).map((key) => {
    const label = _.map(_.toLower(key).split("_"), _.capitalize).join(" ");
    return {
      label,
      key,
      children: <GetSubNotificationContent notificationType={key} />,
    };
  });

  return (
    <Row gutter={[30, 30]}>
      <Col span={24}>
        <Title level={3} className="notification-title">
          {t(Notification_Title)} {loader ? <Loader /> : null}
        </Title>
        <Text className="notification-text">{t(Notification_Subtitle)}</Text>
      </Col>
      <Col span={24}>
        <Tabs
          tabPosition="left"
          defaultActiveKey={notificationModalItem?.[0]?.["key"]}
        >
          {notificationModalItem &&
            notificationModalItem.map((item) => {
              return (
                <Tabs.TabPane tab={item.label} key={item.key}>
                  {item?.children}
                </Tabs.TabPane>
              );
            })}
        </Tabs>
      </Col>
    </Row>
  );
};

export default NotificaitionContent;
