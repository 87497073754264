//** React imports */
import React, { useState, useEffect, useCallback } from "react";
//** React Router imports */
import { useHistory, withRouter } from "react-router-dom";
//** Amplify import */
import { API, Auth, graphqlOperation } from "aws-amplify";
//** Ant imports */
import { Layout } from "antd";

//** Redux login actions */
import {
  logout,
  setPermission,
  updateCompanyAccount,
  setViewType,
  setTheme,
  setIsScopeNormalUser,
  setSchoolData,
  setAccountDetails,
} from "store/actions/loginAction";
//** Redux connect imports */
import { connect, useDispatch, useSelector } from "react-redux";

//** Layout imports */
import { Navbar, Sider } from "Common/Layouts/Layouts";

//** Style imports */
import "Common/Layouts/Layout.less";
// import "index.less";
import UnauthorizedModal from "Common/Components/Modals/UnauthorizedModal";
import { MiscellaneousText } from "utility/constants";
import { useTranslation } from "react-i18next";
import sdk from "sdk/Accounts";
import ScopeContainer from "Common/Layouts/ScopeContainer";
import {
  setAdvancedListVisibility,
  setManageFilesVisibility,
} from "store/actions/settingAction";
import { hasTargetDataSource } from "utility/commonMethods";
import { onUpdateAccount } from "graphql/subscriptions";

//** Destructing Ant Components */
const { Content, Footer } = Layout;

const initialValue = {
  isLoading: true,
  settingsMenuOpen: false,
  darkTheme: true,
  searchBarHandler: false,
  searchView: false,
};

/**
 * MainLayout component
 * @function MainLayout
 * @return {<MainLayout children/>}
 */
const MainLayout = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { themeMode, schoolData } = useSelector((state) => state?.loginReducer);
  const { t } = useTranslation();

  const [state, setState] = useState(initialValue);
  const toggleCollapsed = () => {
    dispatch(setTheme(!themeMode));
  };

  const {
    accountId: accountID,
    groups,
    selectedCompanyName,
    admin_groups_self,
    viewType,
  } = props;

  const { version } = MiscellaneousText;

  const switchAccountSelf = async (account) => {
    const { idToken } = await Auth.currentSession();
    await props.setPermission(idToken.payload);
    props.setViewType("admin");
    dispatch(setIsScopeNormalUser(false));
    await props.updateCompanyAccount({ id: account[0] });
    history.push("/");
  };

  const toggleSettingsMenuOpen = (flag) => {
    setState((pS) => ({ ...pS, settingsMenuOpen: flag }));
  };

  const toggleSearchView = (flag) => {
    setState((pS) => ({ ...pS, searchView: flag }));
  };

  const fetchAccountData = useCallback(async () => {
    if (!accountID) return;

    try {
      let account = await sdk.fetchAccount(accountID);
      const { datasource } = account;

      function updateVisibility(datasource, target, action) {
        dispatch(action(!hasTargetDataSource(datasource, target)));
      }

      updateVisibility(datasource, "custom_files", setManageFilesVisibility);
      updateVisibility(
        datasource,
        "blackbaud_advanced_list_management",
        setAdvancedListVisibility
      );

      dispatch(setAccountDetails(account));
      dispatch(setSchoolData(account));
    } catch (e) {
      console.error(e);
    }
  }, [accountID, dispatch]);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        setState((pS) => ({ ...pS, selectedKey: "home" }));
        break;
      default:
        setState((pS) => ({
          ...pS,
          selectedKey: window.location.pathname.replace("/", ""),
        }));
        break;
    }
  }, []);

  useEffect(() => {
    if (!accountID) return;
    const AccountSubscriber = API.graphql(
      graphqlOperation(onUpdateAccount, { id: accountID })
    ).subscribe({
      next: () => {
        fetchAccountData();
      },
      error: (error) => {
        console.error("Subscription error:", error);
      },
    });

    if (!schoolData || Object.keys(schoolData)?.length === 0) {
      fetchAccountData();
    }

    return () => {
      AccountSubscriber.unsubscribe();
    };
  }, [fetchAccountData, accountID, schoolData]);

  return (
    <>
      <div id="menu-container" className={"slidermenu darkMode"}>
        <ScopeContainer
          groups={groups}
          selectedCompanyName={selectedCompanyName}
          admin_groups_self={admin_groups_self}
          switchAccountSelf={switchAccountSelf}
        />

        <Layout className="layout-wrapper">
          <Sider collapsed={themeMode} toggleCollapsed={toggleCollapsed} />

          <Layout
            id="site-layout"
            className={themeMode ? "layoutCollapsed" : "layout"}
          >
            <Navbar
              groups={groups}
              selectedCompanyName={selectedCompanyName}
              admin_groups_self={admin_groups_self}
              viewType={viewType}
              searchBarHandler={state.searchBarHandler}
              searchView={state.searchView}
              location={props?.location}
              toggleSettingsMenuOpen={toggleSettingsMenuOpen}
              toggleSearchView={toggleSearchView}
              switchAccountSelf={switchAccountSelf}
            />

            <Content className="mainLayout">{props.children}</Content>
            <Footer className="main-layout-footer">
              {t(version)}{" "}
              {process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : "0.1.0"}{" "}
              | SchoolBI © {new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </div>

      <UnauthorizedModal />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    dashboardTourOpen,
    themeMode,
    accountId,
  } = state.loginReducer;
  return {
    prop: state.prop,
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    dashboardTourOpen,
    themeMode,
    accountId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logout()),
    updateCompanyAccount: (account) => dispatch(updateCompanyAccount(account)),
    setPermission: (permission) => dispatch(setPermission(permission)),
    setViewType: (viewType) => dispatch(setViewType(viewType)),
    setTheme: (themeMode) => dispatch(setTheme(themeMode)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
