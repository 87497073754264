import { getAccountForDataSetCustom } from "graphql/customQueries";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { API } from "aws-amplify";
import { createPreviewDetail, updatePreviewDetail } from "graphql/mutations";
import { listPreviewDetails } from "graphql/queries";

const getAllDataset = async (accountId) => {
  const queryData = {
    query: getAccountForDataSetCustom,
    body: {
      id: accountId,
    },
  };

  const data = await GraphqlOperationExecutor(queryData);
  return { dataSet: data?.dataSet?.items, data };
};

const getDatasetData = async (body) =>
  apiExecuter(async () => {
    const url = "/dataset/getdata";
    return await API.post("schoolBIREST", url, { body: body });
  });

const updateDatasetData = async (body) =>
  apiExecuter(async () => {
    const url = "/dataset/updatenames";
    return await API.post("schoolBIREST", url, { body: body });
  });

const resetDatasetData = async (body) =>
  apiExecuter(async () => {
    const url = "/dataset/reset";
    return await API.post("schoolBIREST", url, { body: body });
  });

const disconnectDatasetData = async (body) =>
  apiExecuter(async () => {
    const url = "/datasources/disconnect";
    return await API.post("schoolBIREST", url, { body: body });
  });

const savePreviewOrderData = async (input) => {
  const queryData = {
    query: createPreviewDetail,
    body: {
      input,
    },
  };

  const data = await GraphqlOperationExecutor(queryData);
  console.log({ data });
  return data;
};

const updatePreviewOrderData = async (input) => {
  const queryData = {
    query: updatePreviewDetail,
    body: {
      input,
    },
  };

  const data = await GraphqlOperationExecutor(queryData);
  console.log({ data });
  return data;
};

const getPreviewOrderData = async (filter) => {
  const queryData = {
    query: listPreviewDetails,
    body: {
      filter,
    },
  };

  const data = await GraphqlOperationExecutor(queryData);
  return data;
};

const ManageDatasetSDK = {
  getAllDataset,
  getDatasetData,
  updateDatasetData,
  resetDatasetData,
  disconnectDatasetData,
  savePreviewOrderData,
  getPreviewOrderData,
  updatePreviewOrderData,
};

export default ManageDatasetSDK;
