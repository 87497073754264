//** React imports */
import React, { useState, useEffect, useCallback } from "react";
//** Ant components imports */
import { Form, Select, Button, Col, Row, Input, Table, Empty, Tag } from "antd";
//** Third Party imports */
import Moment from "react-moment";
//** SDK imports */
import sdk from "sdk/Accounts";
//** Skeleton imports */
import SkeletonTable from "Common/Skeleton/SkeletonTable";
//** Common method imports */
import { get_attribute } from "utility/commonMethods";
//** Constant imports */
import { formLabel, MiscellaneousText, adminPanel } from "utility/constants";
//** Third Party imports */
import { useTranslation } from "react-i18next";
import Loader from "Common/Components/Loader/Loader";

export default function Users(props) {
  //** Destructing constants */
  const {
    email,
    firstName,
    lastName,
    name,
    onboardingStatus,
    lastUsed,
    searchUsers,
    selectFilter,
    search,
  } = formLabel;
  const { school, enter } = MiscellaneousText;
  const { userTab } = adminPanel;
  //** Destructing Hooks */
  const { t } = useTranslation();

  //** Select Option */
  const selectOption = [
    {
      label: t(firstName),
      value: t(firstName),
    },
    {
      label: t(lastName),
      value: t(lastName),
    },
    {
      label: t(email),
      value: t(email),
    },
    {
      label: t(school),
      value: t(school),
    },
  ];

  /**
   * @useState
   * State for handling data changes
   */
  //** All users data */
  const [UsersData, setUsersData] = useState([]);
  //** Table loading */
  const [tableLoading, settableLoading] = useState(false);
  const [SearchParams, setSearchParams] = useState({});
  //** Search Param DropDown  */
  const [searchOption, setSearchOption] = useState("Email");
  //** Backup data for getting it while filtering table */
  const [userBackup, setUserBackup] = useState([]);

  /**
   * Getting User From Cognito
   * @function getUserData
   * Store user data to states
   */
  const getUserData = useCallback(async () => {
    settableLoading(true);

    let nextToken = null;
    let allUsersData = [];

    try {
      do {
        const response = await sdk.getAllUsers(nextToken);
        const accounts = response?.data?.listAccounts?.items || [];

        const tempUserData = accounts.flatMap((company) => {
          if (!company?.users) return [];
          return company.users.map((user) => {
            const parsedUser = JSON.parse(user);
            delete company.users; // Remove users array from company
            return { ...parsedUser, Company_Data: company };
          });
        });

        allUsersData = allUsersData.concat(tempUserData);
        nextToken = response?.data?.listAccounts?.nextToken;
      } while (nextToken);

      setUsersData(allUsersData);
      setUserBackup(allUsersData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      settableLoading(false);
    }
  }, []);

  /**
   * handle search of table
   * @function handleSearch
   * @param {Object} value
   */
  const handleSearch = (value) => {
    setUsersData(userBackup);
    setSearchParams({
      Name: value["search_type"],
      Value: value["search_value"] ? value["search_value"]?.toLowerCase() : "",
    });
  };

  /**
   * @author PrithvirajsinhJadav
   * Filters user base upon @searchKey
   * @function searchUser
   * @param {Object} SearchParams
   * @return {State} Filtered result of UsersData
   */
  const searchUser = useCallback((SearchParams) => {
    const { Name } = SearchParams;
    let searchKey;
    switch (Name) {
      case "First Name":
        searchKey = "given_name";
        break;
      case "Last Name":
        searchKey = "family_name";
        break;
      case "Email":
        searchKey = "email";
        break;
      case "School":
        return setUsersData((prevState) =>
          prevState.filter((user) =>
            String(user["Company_Data"]?.company)
              ?.toLowerCase()
              ?.includes(SearchParams?.Value?.toLowerCase().trim())
          )
        );
      default:
        break;
    }
    return setUsersData((prevState) =>
      prevState.filter((user) =>
        String(get_attribute(user.Attributes, searchKey))
          ?.toLowerCase()
          ?.includes(SearchParams?.Value?.toLowerCase().trim())
      )
    );
  }, []);

  /**
   * @variable
   * @UserTable columns data
   */
  const columns = [
    {
      title: <strong>{t(name)}</strong>,
      width: "25%",
      dataIndex: "Attributes",
      key: "Name",
      render: (name) => (
        <>
          {get_attribute(name, "given_name") ? (
            <span>
              {get_attribute(name, "given_name") +
                " " +
                get_attribute(name, "family_name")}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      sorter: (a, b) => {
        var namea = a?.Attributes[2]?.Value + " " + a?.Attributes[3]?.Value;
        var nameb = b?.Attributes[2]?.Value + " " + b?.Attributes[3]?.Value;
        return namea.localeCompare(nameb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <strong>{t(email)}</strong>,
      width: "25%",
      dataIndex: "Attributes",
      key: "Email",
      render: (attr) => <span>{get_attribute(attr, "email")}</span>,
      sorter: (a, b) => {
        var emaila = a?.Attributes[4]?.Value;
        var emailb = b?.Attributes[4]?.Value;
        return String(emaila).localeCompare(emailb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <strong>{t(onboardingStatus)}</strong>,
      width: "20%",
      dataIndex: "UserStatus",
      key: "user_status",
      render: (status) => <span>{status}</span>,
    },
    {
      title: <strong>{t(school)}</strong>,
      width: "20%",
      dataIndex: "Company_Data",
      key: "Company_Name",
      render: (name, record) => (
        <span
          title={t(userTab.adminViewContentText)}
          className="company-name"
          onClick={() => props.selectCompany(record, "user")}
        >
          {name?.company}
        </span>
      ),
      sorter: (a, b) => {
        return a?.Company_Data?.company.localeCompare(b?.Company_Data?.company);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <strong>{t(lastUsed)}</strong>,
      width: "10%",
      dataIndex: "UserCreateDate",
      key: "created_at",
      render: (modifyDate) => (
        <span>
          <Moment date={modifyDate} format="lll" />
        </span>
      ),
      sorter: (a, b) => {
        return String(a.UserCreateDate).localeCompare(b.UserCreateDate);
      },
      defaultSortOrder: "descend",
      showSorterTooltip: false,
      sortDirections: ["descend", "ascend"],
    },
  ];

  /**
   * @useEffect
   * Getting all users from cognito when @component {<User/>} renders
   */
  useEffect(() => {
    getUserData();
  }, [getUserData]);

  /**
   * @useEffect
   * Filtering users when @SearchParams input changes
   */
  useEffect(() => {
    searchUser(SearchParams);
  }, [SearchParams, searchUser]);

  return (
    <div>
      <Row>
        <Col
          lg={{
            offset: 8,
            span: 10,
          }}
          md={{
            offset: 0,
            span: 24,
          }}
          sm={{
            offset: 0,
            span: 24,
          }}
          xs={{ offset: 0, span: 24 }}
        >
          {/** Search data @AntForm Start*/}
          <Form
            name="search_users"
            onFinish={handleSearch}
            initialValues={{ search_type: "Email" }}
            layout="vertical"
            className="school-form"
          >
            <Form.Item
              name="search_type"
              label={t(selectFilter)}
              className="school-form-input"
            >
              <Select
                size="large"
                className="school-form-dropdown"
                onChange={(e) => setSearchOption(e)}
                options={selectOption}
              />
            </Form.Item>
            <Form.Item
              name="search_value"
              label={
                <div className="d-flex gap-3">
                  {t(searchUsers)} {tableLoading ? <Loader /> : null}
                </div>
              }
              className="school-form-input"
            >
              <Input
                className="search-school-container"
                placeholder={`${t(enter)} ${t(searchOption)}`}
              />
            </Form.Item>
            <Form.Item className="companysearchbtn">
              <Button
                htmlType="submit"
                type="primary"
                disabled={tableLoading}
                // className="btn-primary btn-disable"
              >
                {t(search)}
              </Button>
            </Form.Item>
          </Form>
          {/** Search data @AntForm End*/}
        </Col>
      </Row>
      {/** Showing @UserData by  @AntTable */}
      <div>
        <Table
          className="tablecell"
          bordered
          size="middle"
          dataSource={UsersData}
          columns={columns}
          locale={{
            emptyText: tableLoading ? <SkeletonTable /> : <Empty />,
          }}
          scroll={{
            x: 1000,
          }}
        />
      </div>
    </div>
  );
}
