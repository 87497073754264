//** React Imports */
import React, { useEffect, useState } from "react";
//** Redux Imports */
import { connect } from "react-redux";
//** Router Imports */
import { Redirect, Route } from "react-router-dom";
//** Layout Imports */
import FullPageLayout from "containers/FullPageLayout";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

/**
 * Account Setup Routes Higher Order Component
 * @function AccountLayoutRoute
 * @param {Object} props
 */
const AccountLayoutRoute = (props) => {
  //** Destructing Props */
  const { token, groups, render, ...rest } = props;

  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  /**
   * @useEffect
   * Sending message if token does not exist
   */
  useEffect(() => {
    if (!token) {
      setApiResultModal(() => ({
        message: "Please login",
        isApiResultModelOpen: true,
        type: "failure",
      }));
    }
  }, [token]);

  const getCondition = () => {
    if (window.location.host.includes("stag")) {
      return groups?.length > 1 && groups?.includes("stage_users");
    } else {
      return groups?.length > 0;
    }
  };

  return (
    <>
      <Route
        {...rest}
        render={(matchProps) => (
          <>
            {!token && <Redirect from={matchProps.path} to={"/signin"} />}
            {token && getCondition() && (
              <Redirect from={matchProps.path} to="/" />
            )}
            {token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </>
        )}
      />
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
  };
};
export default connect(mapStateToProps)(AccountLayoutRoute);
// export default AccountLayoutRoute;
