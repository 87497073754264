import { Skeleton } from "antd";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import React, { useCallback, useEffect, useState } from "react";
import QSDashboardCard from "./QSDashboardCard";
import QsDashboardSDK from "sdk/QsDashboardSDK";
import { useSelector } from "react-redux";
import ManageDatasetSDK from "sdk/ManageDatasetSDK";
import { partition, sortBy } from "lodash";

const CustomDashboard = () => {
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "success",
  });
  const [data, setData] = useState([]);

  const { accountId: accountID, userId } = useSelector(
    (state) => state.loginReducer
  ); // Getting group from login reducer

  const arrangeDashboardCard = (data = []) => {
    const updatedData = data.map((item) => ({
      ...item,
      isFavoriteOf:
        item.isFavoriteOf?.items?.length > 0
          ? {
              isFavorite: true,
              isFavoriteId: item.isFavoriteOf.items[0]?.id,
            }
          : {
              isFavorite: item.isFavoriteOf?.isFavorite || false,
              isFavoriteId: item.isFavoriteOf?.isFavoriteId || null,
            },
    }));

    const [favorites, nonFavorites] = partition(
      updatedData,
      (item) => item.isFavoriteOf.isFavorite
    );

    const sortPrescriptive = (items) => {
      const prescriptive = items.filter(
        (item) => item.dashboard_type === "PRESCRIPTIVE_DASHBOARD"
      );
      const nonPrescriptive = items.filter(
        (item) => item.dashboard_type !== "PRESCRIPTIVE_DASHBOARD"
      );

      const sortedPrescriptive = sortBy(prescriptive, [
        (item) => item.dashboard_name !== "admissions_management_system",
      ]);

      return [...nonPrescriptive, ...sortedPrescriptive];
    };

    const sortedFavorites = sortPrescriptive(favorites);
    const sortedNonFavorites = sortPrescriptive(nonFavorites);

    return [...sortedFavorites, ...sortedNonFavorites];
  };

  const manipulateDashboardCard = (id, data) => {
    setData((pS) => {
      const index = pS.findIndex((item) => item.id === id);

      if (index > -1) pS[index] = { ...pS[index], ...data };

      return [...pS];
    });
  };

  const fetchData = useCallback(async () => {
    try {
      setIsSkeletonLoading(true);
      const filter = {
        or: [{ school_id: { eq: "" } }, { school_id: { eq: accountID } }],
      };
      const qsDashboarddata = await QsDashboardSDK.getAllCustomDashboard(
        filter,
        userId
      ).catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      );
      const { dataSet } = await ManageDatasetSDK.getAllDataset(accountID).catch(
        (e) =>
          setApiResultModal(() => ({
            message: e?.message || "Something went wrong",
            isApiResultModelOpen: true,
            type: "failure",
          }))
      );
      const uniqueDataSets = new Set(
        dataSet
          .filter(({ status }) => status === "CONNECTED")
          .map(({ data_set }) => data_set?.toLowerCase())
      );

      const qsDashboarddataHandle = qsDashboarddata?.filter(
        ({ dashboard_name, dashboard_type }) => {
          if (dashboard_type === "PRESCRIPTIVE_DASHBOARD") {
            return uniqueDataSets?.has(dashboard_name?.toLowerCase());
          }
          return true;
        }
      );

      const arrangeCard = arrangeDashboardCard(qsDashboarddataHandle);
      setData(arrangeCard);
    } catch (e) {
      console.error("Something went wrong", e);
    } finally {
      setIsSkeletonLoading(false);
    }
  }, [accountID, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {isSkeletonLoading ? (
        <Skeleton active={true} />
      ) : (
        <QSDashboardCard
          data={data}
          manipulateDashboardCard={manipulateDashboardCard}
          arrangeDashboardCard={arrangeDashboardCard}
          setData={setData}
        />
      )}
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default CustomDashboard;
