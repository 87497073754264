import { useCallback } from "react";
import { useSelector } from "react-redux";

export enum Permission {
  AdminSchoolBilling = "AdminSchoolBilling",
  AdminSchools = "AdminSchools",
  AssignBillingPlan = "AssignBillingPlan",
  ConfigureDataSources = "ConfigureDataSources",
  ConfigureMFA = "ConfigureMFA",
  DisableSchool = "DisableSchool",
  EditCustomDashboardMeta = "EditCustomDashboardMeta",
  InviteUsers = "InviteUsers",
  ManagePaymentMethod = "ManagePaymentMethod",
  ManageSchoolSecretKey = "ManageSchoolSecretKey",
  ManageSiteFAQ = "ManageSiteFAQ",
  UseStaging = "UseStaging",
  ViewBilling = "ViewBilling",
  ViewCustomDashboard = "ViewCustomDashboard",
  ViewPrescriptiveDashboard = "ViewPrescriptiveDashboard",
  ViewUsage = "ViewUsage",
}

const permissionGroups = {
  [Permission.UseStaging]: ["stage_user"],
  [Permission.InviteUsers]: ["SchoolAdmin", "SchoolAccountOwner"],
  [Permission.ManagePaymentMethod]: ["SchoolAccountOwner"],
  [Permission.ViewBilling]: ["SchoolAccountOwner", "SchoolAdmin"],
  [Permission.ViewUsage]: ["SchoolAccountOwner", "SchoolAdmin", "normal_user"],
  [Permission.ViewPrescriptiveDashboard]: [
    "SchoolAccountOwner",
    "SchoolAdmin",
    "normal_user",
    "ReportUser",
  ],
  [Permission.ViewCustomDashboard]: [
    "SchoolAccountOwner",
    "SchoolAdmin",
    "normal_user",
  ],
  [Permission.EditCustomDashboardMeta]: [
    "SchoolAccountOwner",
    "SchoolAdmin",
    "normal_user",
  ],
  [Permission.ConfigureDataSources]: ["SchoolAccountOwner", "SchoolAdmin"],
  [Permission.AssignBillingPlan]: ["admin", "SchoolAccountOwner"],
  [Permission.ManageSiteFAQ]: ["admin"],
  [Permission.ManageSchoolSecretKey]: ["SchoolAccountOwner"],
  [Permission.ConfigureMFA]: ["SchoolAccountOwner"],
};

export const usePermissions = () => {
  const { groups } = useSelector(
    (state: { loginReducer: { groups: string[] } }) => state.loginReducer
  );

  const can: (p: Permission) => boolean = useCallback(
    (permission: Permission) => {
      //admin can do anything
      if (groups.includes("admin")) {
        return true;
      }

      return (
        permissionGroups.hasOwnProperty(permission) &&
        permissionGroups[permission].reduce((acc, current) => {
          return acc + (groups.includes(current) ? 1 : 0);
        }, 0) > 0
      );
    },
    [groups]
  );

  const cannot: (p: Permission) => boolean = useCallback(
    (permission: Permission) => {
      return !can(permission);
    },
    [can]
  );

  return { can, cannot };
};
