import { Drawer, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import QsDashboardSDK from "sdk/QsDashboardSDK";

const QSAIInsight = ({ body, open, onClose }) => {
  const [quicksightLoader, setQuickSightLoader] = useState(false);
  const [urlQNAUrl, setQNAUrl] = useState(false);

  const embed = async (url) => {
    const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
    const dashboardLoadCallback = () => setQuickSightLoader(false);
    const dashboardErrorCallback = () => setQuickSightLoader(false);

    const frameOptions = {
      url,
      container: "#quick-sight-ai",
      height: "700px",
      width: "1000px",
      loadCallback: dashboardLoadCallback,
      errorCallback: dashboardErrorCallback,
    };

    await embeddingContext.embedGenerativeQnA(frameOptions);
    setQuickSightLoader(false);
  };

  const getURL = useCallback(async () => {
    setQuickSightLoader(true);
    const data = await QsDashboardSDK.getAIInsight(body);
    embed(data?.url);
    setQNAUrl(true);
  }, [body]);

  useEffect(() => {
    if (open && !urlQNAUrl) {
      getURL();
    }
  }, [open, urlQNAUrl, getURL]);

  return (
    <>
      <Drawer
        open={open}
        width={1024}
        title="AI Insight"
        className="ai-insight-drawer"
        onClose={onClose}
      >
        <div className="quicksight-dashboard-view-container">
          {quicksightLoader && (
            <div className="quicksight-loader">
              <div className="dashboard-loader">
                <Skeleton active={true} />
              </div>
            </div>
          )}
          <div className="wrapperBox">
            <div className="iframeBox" id="quick-sight-ai" />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default QSAIInsight;
