//** React Imports */
import React, { useState, useEffect, useCallback } from "react";
//** Ant design Imports */
import { Typography, Form, Select, Button } from "antd";
//** Images Imports */
import InviteModalIcon from "assets/images/InviteMember.svg";
//** Common Methods */
import {
  validateEmail,
  apiExecuter,
  sendInvitation,
} from "utility/commonMethods";
//** Constant Imports */
import { modalText, buttonText, formLabel, formError } from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Redux Imports */
import { useSelector } from "react-redux";
import CustomModal from "../CustomAntD/Modal";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

//** Importing State from Parent Components */
const InviteModal = ({
  isInviteModelOpen,
  setIsInviteModelOpen,
  currentUserDetails,
  handleFetchAccountDetails,
}) => {
  //** Getting Values From Redux */
  const { isLoading } = useSelector((state) => state.loginReducer);
  const { userData, scopedUserDetails } = useSelector(
    (state) => state.loginReducer
  );

  //** Ant Design Invite Form */
  const [inviteForm] = Form.useForm();

  /** Account Data @useState */
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "success",
  });

  // console.log("apiResultModal outside :: ", apiResultModal);

  //** Destructing Hooks */
  const { t } = useTranslation();

  //** Destructing Constants */
  const { Invite_Text } = modalText.Invite_Modal;
  const { Share_Invite, Invite_Team_Title } = buttonText;
  const { emailText } = formLabel;
  const { isFieldEmpty } = formError;

  //** Handles Toggling State */
  const handleCancel = () => {
    console.log("console");
    setIsInviteModelOpen(false);
  };

  //** validating email */
  const handleValidateEmail = (e) => validateEmail(e);

  /**
   *  Sending invitation to all email list
   * @function sendInvitation
   * @param {Object} value it contains account details
   */
  const handleSendInvitation = (value) => {
    sendInvitation(
      {
        inviteBy:
          currentUserDetails?.email ||
          scopedUserDetails?.email ||
          userData?.email,
      },
      value
    )
      .then((res) => {
        if (handleFetchAccountDetails) {
          handleFetchAccountDetails();
        }
        inviteForm.resetFields();
        if (res.error) {
          setApiResultModal(() => ({
            message: t(res?.error),
            isApiResultModelOpen: true,
            type: "failure",
          }));
          return;
        }
        setApiResultModal(() => ({
          message: t(res?.message),
          isApiResultModelOpen: true,
          type: "success",
        }));
      })
      .catch((res) => {
        setApiResultModal(() => ({
          message: t(res?.error),
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => {
        handleCancel();
      });
  };

  /**
   * @function onInviteSend
   *  Send Invite Button Handler
   */
  const onInviteSend = () => {
    apiExecuter(() => {
      inviteForm
        .validateFields()
        .then((values) => {
          const formatValues = {
            ...values,
          };
          handleSendInvitation(formatValues);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    });
  };

  return (
    <>
      <CustomModal
        visible={isInviteModelOpen}
        closable={true}
        centered
        width={450}
        footer={null}
        className="suspend-company-footer br_20"
        onCancel={handleCancel}
      >
        <div className="delete-modal-body">
          <img
            src={InviteModalIcon}
            alt="suspend company"
            className="mx-auto d-flex"
          />
          <Typography.Title level={3} className="d-flex typography-title pt_13">
            {t(Invite_Team_Title)}
          </Typography.Title>
          <Typography.Text className="d-flex typography-text">
            {t(Invite_Text)}
          </Typography.Text>
          <Form layout="vertical" form={inviteForm} className="pt_13">
            <Form.Item
              label={
                <span>
                  <strong>{t(emailText)}</strong>
                </span>
              }
              name="emails"
              rules={[
                {
                  required: true,
                  message: t(isFieldEmpty),
                },
              ]}
            >
              <Select
                // maxTagCount={2}
                mode="tags"
                defaultOpen
                className="w-100 mb_5 invitemebers"
                tokenSeparators={[","]}
                notFoundContent=""
                onChange={(e) => handleValidateEmail(e)}
                placeholder={
                  <span className="text-grey"> {t(emailText)} </span>
                }
              ></Select>
            </Form.Item>
          </Form>
          <Button
            className="d-flex w-100 modal-btn btn-primary"
            loading={isLoading}
            onClick={() => onInviteSend()}
          >
            {t(Share_Invite)}
          </Button>
        </div>
      </CustomModal>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default InviteModal;
