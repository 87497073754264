import { API } from "aws-amplify";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { updateGoogleSheetDetail } from "graphql/mutations";
import {
  getAccountForGoogleSheetCustom,
  listDataSetupsCustom,
} from "graphql/customQueries";

const googleSheetLinkHandler = (body) =>
  apiExecuter(async () => {
    const url = "/customdata/googlesheetlink";
    return await API.post("schoolBIREST", url, { body: body });
  });

const tableConfigurationHandler = (body) => {
  return apiExecuter(async () => {
    const url = "/customdatauploadasync";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const previewFileHandler = (body) => {
  return apiExecuter(async () => {
    const url = "/customdata/previewdata";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const updateRefreshHandler = (inputData) => {
  const queryData = {
    query: updateGoogleSheetDetail,
    body: {
      input: inputData,
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const getAllGoogleSheet = async (accountId) => {
  const queryData = {
    query: getAccountForGoogleSheetCustom,
    body: {
      id: accountId,
    },
  };

  const data = await GraphqlOperationExecutor(queryData);

  return {
    request_googlesheet: data?.request_googlesheet?.items,
    users: data.users.map((item) => JSON.parse(item)),
  };
};

const cancelledGoogleSheetHandler = (body) => {
  return apiExecuter(async () => {
    const url = "/customdata/tryagain";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const isCancelledHandler = (inputData) => {
  const queryData = {
    query: updateGoogleSheetDetail,
    body: {
      input: inputData,
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const terminateSheetHandler = (body) => {
  return apiExecuter(async () => {
    const url = "/customdata/terminatefile";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const deleteGoogleSheetDetailHandler = async (record) => {
  const { googlesheet_id, worksheet_id, id, school_id } = record;

  const body = {
    googlesheet_id,
    worksheet_id,
    id,
    school_id,
  };

  return apiExecuter(async () => {
    const url = "/customdata/deletefile";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const onDemandSync = async (record) => {
  const {
    googlesheet_id,
    worksheet_id,
    id,
    school_id,
    googlesheet_name,
    worksheet_name,
    interval_type,
    googlesheet_link,
  } = record;

  const body = {
    googlesheet_id,
    worksheet_id,
    id,
    school_id,
    googlesheet_name,
    worksheet_name,
    interval_type,
    googlesheet_link,
  };

  return apiExecuter(async () => {
    const url = "/customdata/ondemand/sync";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const getFileFieldsData = async ({ school_id, worksheet_id }) => {
  const queryData = {
    query: listDataSetupsCustom,
    body: {
      limit: 999,
      filter: {
        school_id: { eq: school_id },
        worksheet_id: { eq: worksheet_id },
      },
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const getWorksheetFields = async (record) => {
  const { sheet_name, googleSheetLink } = record;

  return apiExecuter(async () => {
    const url = `/customdata/googlesheet/datatype`;
    const body = {
      sheet_name,
      googleSheetLink,
    };

    return await API.post("schoolBIREST", url, { body: body });
  });
};

const getCSVFields = async (input) =>
  apiExecuter(async () => {
    const url = `/customdata/csv/datatype`;
    const body = {
      ...input,
    };

    return await API.post("schoolBIREST", url, { body: body });
  });

const setCSVFields = async (input) =>
  apiExecuter(async () => {
    const url = `/csv/upload`;
    const body = {
      ...input,
    };

    return await API.post("schoolBIREST", url, { body: body });
  });

const ManageFileSDK = {
  googleSheetLinkHandler,
  tableConfigurationHandler,
  previewFileHandler,
  updateRefreshHandler,
  getAllGoogleSheet,
  cancelledGoogleSheetHandler,
  isCancelledHandler,
  terminateSheetHandler,
  deleteGoogleSheetDetailHandler,
  onDemandSync,
  getFileFieldsData,
  getWorksheetFields,
  getCSVFields,
  setCSVFields,
};

export default ManageFileSDK;
