import { Empty, Select, Table } from "antd";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
const { getFieldKey } = require("utility/commonMethods");

const ConfigureTable = ({
  columns,
  uniqueField,
  handleWorkSheetConfig,
  setWorkSheetConfig,
  isLoading,
}) => {
  const getObjectForTable = (item) => ({
    [getFieldKey(item)]: {
      friendly_name: item,
      field_description: item,
    },
  });

  const getDateForTable = (item) => moment(item).format("YYYY-MM-DD");

  const [tableConfigureData, setTableConfigureData] = useState(null);

  useEffect(() => {
    const tableConfigurationData = {
      metrics: {},
      dimension: {},
    };
    Object.keys(columns)?.forEach((item, index) => {
      if (columns[item] === "NUMBER") {
        tableConfigurationData.metrics[getFieldKey(item)] =
          getObjectForTable(item)[getFieldKey(item)];
        tableConfigurationData.metrics[getFieldKey(item)].field_type =
          columns[item] || "NUMBER";
        return;
      }
      tableConfigurationData.dimension[getFieldKey(item)] =
        getObjectForTable(item)[getFieldKey(item)];
      tableConfigurationData.dimension[getFieldKey(item)].field_type =
        columns[item] || "TEXT";
    });

    handleWorkSheetConfig("tableConfigurationData", tableConfigurationData);

    setTableConfigureData(() => {
      return Object.keys(columns)?.map((colItem, index) => {
        const dataType = columns[colItem];

        return {
          colName: colItem,
          key: getFieldKey(colItem),
          dataType: dataType,
          fieldType: dataType === "NUMBER" ? "metrics" : "dimension",
        };
      });
    });
  }, [columns, handleWorkSheetConfig]);

  const handleChange = (colName, value, dataType) => {
    setWorkSheetConfig((pS) => {
      const { tableConfigurationData } = pS;
      const key = getFieldKey(colName);
      const isDimension = tableConfigurationData.dimension.hasOwnProperty(key);
      const isMetric = tableConfigurationData.metrics.hasOwnProperty(key);

      switch (dataType) {
        case "dataTypes":
          if (isDimension) {
            tableConfigurationData.dimension[key].field_type = value;
          }
          if (isMetric) {
            tableConfigurationData.metrics[key].field_type = value;
          }
          setTableConfigureData((pS) => {
            const data = pS.filter((item) => item.colName === colName)?.[0];
            data["dataType"] = value;
            return [...pS];
          });
          break;
        case "metricAndDimension":
          let dataType, attributeType, startDate, endDate;
          if (isDimension) {
            dataType = tableConfigurationData.dimension[key].field_type;
            attributeType =
              tableConfigurationData.dimension[key].user_attribute;
            startDate = tableConfigurationData.dimension[key].start_date;
            endDate = tableConfigurationData.dimension[key].end_date;

            delete tableConfigurationData.dimension[key];
          }
          if (isMetric) {
            dataType = tableConfigurationData.metrics[key].field_type;
            attributeType = tableConfigurationData.metrics[key].user_attribute;
            startDate = tableConfigurationData.metrics[key].start_date;
            endDate = tableConfigurationData.metrics[key].end_date;

            delete tableConfigurationData.metrics[key];
          }
          tableConfigurationData[value][key] = {
            ...getObjectForTable(colName)[key],
            field_type: dataType,
            user_attribute: attributeType,
            start_date: startDate,
            end_date: endDate,
          };

          setTableConfigureData((pS) => {
            const data = pS.filter((item) => item.colName === colName)?.[0];
            data["fieldType"] = value;
            return [...pS];
          });
          break;
        case "attributeType":
          if (isDimension) {
            tableConfigurationData.dimension[key].user_attribute = value;
          }
          if (isMetric) {
            tableConfigurationData.metrics[key].user_attribute = value;
          }

          setTableConfigureData((pS) => {
            const data = pS.filter((item) => item.colName === colName)?.[0];
            data["attributeType"] = value;
            return [...pS];
          });
          break;
        case "startAndEndDate":
          if (isDimension) {
            tableConfigurationData.dimension[key].start_date =
              getDateForTable(value?.[0]) || null;
            tableConfigurationData.dimension[key].end_date =
              getDateForTable(value?.[1]) || null;
          }
          if (isMetric) {
            tableConfigurationData.metrics[key].start_date =
              getDateForTable(value?.[0]) || null;
            tableConfigurationData.metrics[key].end_date =
              getDateForTable(value?.[1]) || null;
          }
          setTableConfigureData((pS) => {
            const data = pS.filter((item) => item.colName === colName)?.[0];
            data["startType"] = getDateForTable(value?.[0]) || null;
            data["endType"] = getDateForTable(value?.[1]) || null;

            return [...pS];
          });
          break;
        default:
          break;
      }

      return { ...pS };
    });
  };

  const dataTypesOptions = [
    {
      label: "Boolean",
      value: "BOOLEAN",
    },
    {
      label: "Date",
      value: "DATETIME",
    },
    {
      label: "Number",
      value: "NUMBER",
    },
    {
      label: "Text",
      value: "TEXT",
    },
  ];

  const metricsAndDimensionsOptions = [
    {
      label: "Metrics",
      value: "metrics",
    },
    {
      label: "Dimensions",
      value: "dimension",
    },
  ];

  const attributesTypeOptions = [
    {
      label: "Life Time",
      value: "LIFETIME",
    },
    {
      label: "Time Bound",
      value: "TIMEBOUND",
    },
  ];

  const getDataType = (colName, type) =>
    tableConfigureData.filter((item) => item.colName === colName)?.[0]?.[type];

  const configureTableData = [
    {
      title: "Columns Name",
      dataIndex: "colName",
      key: "colName",
      width: "20%",
      render: (item) => <span className="column-name-wrapper">{item}</span>,
    },
    {
      title: "Metrics/Dimension",
      dataIndex: "metricsAndDimension",
      key: "metricsAndDimension",
      width: "31%",
      render: (text, render) => {
        return (
          <Select
            options={metricsAndDimensionsOptions}
            defaultValue={
              render?.dataType === "NUMBER"
                ? "metrics"
                : "dimension" || "metrics"
            }
            onChange={(value) =>
              handleChange(render?.colName, value, "metricAndDimension")
            }
          />
        );
      },
    },
    {
      title: "Data Types",
      dataIndex: "dataTypes",
      key: "dataTypes",
      width: "25%",
      render: (text, render) => {
        return (
          <Select
            options={dataTypesOptions}
            value={getDataType(render?.colName, "dataType") || "TEXT"}
            onChange={(value) =>
              handleChange(render?.colName, value, "dataTypes")
            }
          />
        );
      },
    },
    // {
    //   title: "Attributes Type",
    //   dataIndex: "attributes_type",
    //   key: "attributesType",
    //   width: "30%",
    //   render: (_, render) => (
    //     <Select
    //       options={attributesTypeOptions}
    //       value={getDataType(render?.colName, "attributeType") || "LIFETIME"}
    //       onChange={(value) =>
    //         handleChange(render?.colName, value, "attributeType")
    //       }
    //     />
    //   ),
    // },
    // {
    //   title: "Start & End date",
    //   dataIndex: "start_end_date",
    //   key: "attributesType",
    //   width: "50%",
    //   render: (_, render) => (
    //     <RangePicker
    //       // value={getDataType(render?.colName, "startDate") || "TEXT"}
    //       onChange={(value) => {
    //         handleChange(render?.colName, value, "startAndEndDate");
    //       }}
    //       disabled={
    //         getDataType(render?.colName, "attributeType") === "LIFETIME"
    //       }
    //     />
    //   ),
    // },
    Table.SELECTION_COLUMN,
  ];

  const rowSelection = {
    type: "radio",
    columnTitle: "Unique Id",
    columnWidth: "17.5%",
    onChange: (_, selectedRows) => {
      handleWorkSheetConfig(
        "uniqueField",
        getFieldKey(selectedRows[0]?.colName)
      );
    },

    selectedRowKeys: [
      tableConfigureData?.filter((item) => item?.key === uniqueField)[0]?.key,
    ],
    preserveSelectedRowKeys: true,
  };

  return (
    <Table
      columns={configureTableData}
      dataSource={tableConfigureData}
      pagination={false}
      className="configure-table-wrapper"
      scroll={{
        y: 425,
      }}
      locale={{
        emptyText: isLoading ? <SkeletonTable /> : <Empty />,
      }}
      rowSelection={rowSelection}
    />
  );
};

export default ConfigureTable;
