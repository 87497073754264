import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import CustomModal from "Common/Components/CustomAntD/Modal";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import RulesEngineSDK from "sdk/RulesEngineSDK";
import { buttonText } from "utility/constants";

const RuleEngine = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const carouselRef = useRef();

  const [ruleEngineForm] = Form.useForm();

  const { Done_Btn } = buttonText;

  const [currentSlideValue, setCurrentSlideValue] = useState(0);
  const [ruleEngineModal, setRuleEngineModal] = useState(false);
  const [ruleEngineData, setRuleEngineData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { accountId } = useSelector((state) => state.loginReducer);

  const ruleEngineColumns = [
    {
      title: "Data Set",
      dataIndex: "data_set",
      key: "data_set",
      render: (item) => _.startCase(_.toLower(item)),
      sorter: (a, b) => String(a?.data_set).localeCompare(b?.data_set),
    },
    {
      title: "Data Source",
      dataIndex: "data_source",
      key: "data_source",
      render: (item) => _.startCase(_.toLower(item)),
      sorter: (a, b) => String(a?.data_source).localeCompare(b?.data_source),
    },
    {
      title: "Field Key",
      dataIndex: "field_key",
      key: "field_key",
      sorter: (a, b) => String(a?.field_key).localeCompare(b?.field_key),
    },
    {
      title: "Rule Type",
      dataIndex: "rule_type",
      key: "rule_type",
      sorter: (a, b) => String(a?.rule_type).localeCompare(b?.rule_type),
    },
    {
      title: "Rules Value",
      dataIndex: "rule_values",
      key: "rule_values",
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "lastUpdated",
      render: (item) => (item ? <Moment format="lll" date={item} /> : "-"),
      sorter: (a, b) => String(a?.updatedAt).localeCompare(b?.updatedAt),
    },
  ];

  const goToSlide = (index) => {
    setCurrentSlideValue(index);
    carouselRef.current.goTo(index);
  };

  const onChange = async (currentSlide) => {
    setCurrentSlideValue(currentSlide);
    goToSlide(currentSlide);
  };

  const handleRuleEngineUpdate = async () => {
    console.log("Hello World handleRuleEngineUpdate");
    const inputValues = ruleEngineForm?.getFieldsValue();

    const filteredKeys = Object.keys(inputValues).filter((item) =>
      item?.includes("tobeupdatevalues_")
    );

    const ruleValues = {};

    filteredKeys.forEach(
      (key) =>
        (ruleValues[[inputValues[key]]] =
          inputValues[`updated_${inputValues[key]}`])
    );

    const input = {
      school_id: accountId,
      rule_type: "REPLACE",
      rule_values: JSON.stringify(ruleValues),
    };

    const data = await RulesEngineSDK?.createRuleEngine(input);
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={
          className + (currentSlideValue === 1 ? " last-slick-slide" : "")
        }
        style={{
          ...style,
          display: "block",
          top: "unset",
          bottom: "-10%",
          left: "90%",
        }}
        onClick={onClick}
      >
        {currentSlideValue === 1 ? (
          <Button
            type="primary"
            onClick={() => handleRuleEngineUpdate()}
            style={{
              marginTop: 16,
            }}
          >
            {t(Done_Btn)}
          </Button>
        ) : null}
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={
          className + (currentSlideValue === 1 ? " last-slick-slide" : "")
        }
        style={{
          ...style,
          display: "block",
          top: "unset",
          bottom: "-10%",
          right: 0,
        }}
        onClick={onClick}
      >
        {currentSlideValue === 1 ? null : (
          <ArrowRightOutlined
            style={{ color: "black", fontSize: 25, marginTop: 16 }}
          />
        )}
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [fieldKeys, setFieldKeys] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);

  const getRuleEngine = useCallback(async () => {
    setIsLoading(true);
    const filter = {
      school_id: { eq: accountId },
      rule_type: { eq: "REPLACE" },
    };
    const data = await RulesEngineSDK.getRuleEngine(filter);
    setIsLoading(false);
    setRuleEngineData(data);
  }, [accountId]);

  useEffect(() => {
    getRuleEngine();
  }, [getRuleEngine]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Table
          columns={ruleEngineColumns}
          dataSource={ruleEngineData}
          className="managedatasets-table-container advanced-table-container"
          pagination={false}
          locale={{
            emptyText: isLoading ? <SkeletonTable /> : <Empty />,
          }}
          scroll={{
            x: 1000,
          }}
        />
      </Col>

      <CustomModal
        visible={ruleEngineModal}
        closable={true}
        width={"60%"}
        centered
        onCancel={() => setRuleEngineModal(false)}
        footer={null}
        bodyStyle={{
          height: "600px",
          overflow: "hidden",
          borderRadius: 10,
        }}
        className="carousel-container configure-table-modal refresh-interval-modal "
      >
        <Carousel
          afterChange={onChange}
          arrows
          {...settings}
          className="uploadedmultisheet-container"
          ref={carouselRef}
          destroyOnClose={true}
        >
          <div>
            <Title
              level={4}
              style={{
                fontWeight: "bold",
              }}
            >
              Add New Rule
            </Title>
            <Form layout="vertical">
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <Form.Item label="Rule Name">
                    <Input placeholder="type here" value={"DASL Rule"} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Author">
                    <Input placeholder="type here" value={"Jeffery Bowers"} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Version">
                    <Input placeholder="type here" value={"0.1.0"} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Updated Date">
                    <Input placeholder="type here" value={"23/3/2024"} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Description">
                    <Input.TextArea
                      rows={6}
                      placeholder="maxLength is 6"
                      value={
                        "Updating terminology as per DASL standards and specifications"
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <Form layout="vertical" form={ruleEngineForm}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item label="Select Data Fields" name="fieldNames">
                    <Select
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className="select-container"
                      onChange={(item) => {
                        console.log("ITEMS :: ", item);
                        setFieldNames(item);
                      }}
                      options={fieldKeys}
                    />
                  </Form.Item>
                </Col>
                {fieldNames?.length > 0 ? (
                  <>
                    <Col span={12}>
                      <Title level={5}>Column Values</Title>
                    </Col>
                    <Col span={12}>
                      <Title level={5}>Update Values</Title>
                    </Col>

                    {fieldNames?.map((item) => {
                      return (
                        <>
                          <Col span={12}>
                            <Form.Item
                              name={`tobeupdatevalues_${item}`}
                              initialValue={item}
                              // value={item}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name={`updated_${item}`}>
                              <Input placeholder="type here or select" />
                            </Form.Item>
                          </Col>
                        </>
                      );
                    })}
                  </>
                ) : null}
              </Row>
            </Form>
          </div>
          {/* <div>
            <>3</>
          </div> */}
        </Carousel>
      </CustomModal>
    </Row>
  );
};

export default RuleEngine;
