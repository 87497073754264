//** React Imports */
import React, { useState, useEffect } from "react";
//** React Router Imports */
import { Link, useHistory } from "react-router-dom";
//** Ant Design Imports */
import { Menu } from "antd";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Redux Imports */
import { useSelector } from "react-redux";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
import { AvatarLinks } from "utility/constants";
import { Permission, usePermissions } from "../hooks/usePermissions";

//** Handles Avatar Dropdown Menu */
const DropDownMenu = (props) => {
  //** Destructing Hooks */
  const history = useHistory();
  const { t } = useTranslation();
  const { path } = props;
  const { Profile_Link, School_Settings_Text, Super_Admin_Text, Log_Out_Text } =
    AvatarLinks;
  //** Redux States */
  const { can } = usePermissions();
  //** states for handling dropdown */
  const [selectedKey, setselectedKey] = useState("");

  const handleSignOut = async () => {
    Auth.signOut()
      .then((data) => {
        localStorage.clear();
        history.push("/signin");
        props.handleSignOut();
      })
      .catch((err) => {
        if (err?.code === "NotAuthorizedException") {
          localStorage.clear();
        }
        console.log(err);
      });
  };

  useEffect(() => {
    const menukeys = ["/profile", "/school-settings", "/admin"];
    if (menukeys.includes(path)) {
      setselectedKey(path?.split("/")[1]);
    } else {
      setselectedKey("");
    }
  }, [path]);

  return (
    <Menu selectedKeys={selectedKey} className="settingsMenu">
      <Menu.Item key="profile">
        <Link to={"/profile"}>{t(Profile_Link)}</Link>
      </Menu.Item>
      <Menu.Item key="school-settings">
        <Link to={"/school-settings"}>{t(School_Settings_Text)}</Link>
      </Menu.Item>
      {can(Permission.AdminSchools) && (
        <Menu.Item key="admin">
          <Link to={"/admin"}>{t(Super_Admin_Text)}</Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to={"/signin"} onClick={handleSignOut}>
          {t(Log_Out_Text)}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default DropDownMenu;
