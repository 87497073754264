import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Empty, Menu, Row, Table, Tag, Tooltip } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import {
  onCreateCsvDetail,
  onDeleteCsvDetail,
  onUpdateCsvDetail,
} from "graphql/subscriptions";
import _, { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import CSVFilesSDK from "sdk/CSVFilesSDK";
import { get_attribute } from "utility/commonMethods";
import FilePreviewContainer from "./FilePreviewContainer";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import classNames from "classnames";

const CSVFiles = () => {
  const { accountId: accountID } = useSelector((state) => state.loginReducer);

  const [users, setUsers] = useState([]);
  const [CSVDatasource, setCSVDatasource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreviewTable, setFilePreviewTable] = useState(false);
  const [filePreviewTableColData, setFilePreviewTableColData] = useState([]);
  const [filePreviewTableData, setFilePreviewTableData] = useState([]);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  const getCSVStatus = (csvFileStatus) => {
    switch (csvFileStatus) {
      case "CONNECTED":
        return "#16a34a";
      case "DISCONNECTED":
        return "#ed4b4b";
      case "INPROGRESS":
        return "#ffc90a";
      case "FAILED":
        return "#ed4b4b";
      case "DELETING":
        return "#ed4b4b";
      case "STARTING":
        return "#808080";
      default:
        break;
    }
  };

  const handleCSVPreview = async (record) => {
    const { csv_name } = record;
    const body = {
      account: accountID,
      csv_name,
    };
    setIsLoading(true);
    await CSVFilesSDK.getCSVPreview(body)
      .then((res) => {
        const colData = res?.map((item, index) => ({
          "Sr No": index + 1,
          ...item,
        }));

        const fieldsData = _.union(
          Object.keys(colData[0]),
          Object.keys(colData[0])
        );
        setFilePreviewTableColData(_.zipObject(fieldsData, fieldsData));
        setFilePreviewTableData(colData.slice(0, 100));
        setFilePreviewTable(true);
        return res;
      })
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => setIsLoading(false));
  };

  const CSVCols = [
    {
      title: "File Name",
      dataIndex: "csv_name",
      key: "filename",
      render: (googlesheetName, record) => {
        return (
          <span
            onClick={() => handleCSVPreview(record)}
            className={classNames("cursor-none", {
              "cursor-pointer": record?.status === "CONNECTED",
            })}
          >
            {googlesheetName ? googlesheetName : "-"}
          </span>
        );
      },
    },
    {
      title: "File Upload Time & Date",
      dataIndex: "createdAt",
      key: "fileUploadTimeAndDate",
      render: (modifyDate) => <Moment date={modifyDate} format="llll" />,
    },
    {
      title: "File Status",
      dataIndex: "status",
      key: "status",
      render: (isIntervalType) => (
        <Tag
          color={getCSVStatus(isIntervalType)}
          className="refreshinterval-tag"
        >
          {isIntervalType ?? "Not Found"}
        </Tag>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      render: (currentUserId) => {
        if (!currentUserId) {
          return "N/A";
        }

        const filteredUser = users.filter(
          ({ Username }) => currentUserId === Username
        );

        if (isEmpty(filteredUser)) {
          return "Deleted User";
        }

        return `${get_attribute(
          filteredUser[0]["Attributes"],
          "given_name"
        )} ${get_attribute(filteredUser[0]["Attributes"], "family_name")}`;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="d-flex justify-content-center cursor-pointer">
            <Dropdown
              overlay={
                <Menu disabled={record?.status !== "CONNECTED" || isLoading}>
                  <Menu.Item onClick={() => deleteCSV(record)} key="4">
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <MoreOutlined className="fs-24 primary-color" />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const getCSVs = useCallback(async () => {
    setIsLoading(true);
    const body = {
      filter: {
        school_id: { eq: accountID },
      },
    };
    const data = await CSVFilesSDK.getCSVs(body)
      .then(async (res) => {
        if (accountID) {
          const body = {
            id: accountID,
          };
          const allUsers = await CSVFilesSDK.getUsersCustom(body);
          setUsers(allUsers);
        }
        return res;
      })
      .finally(() => setIsLoading(false));

    setCSVDatasource(data);
  }, [accountID]);

  const deleteCSV = async (record) => {
    const { school_id, csv_name, id } = record;
    const body = {
      school_id,
      id,
      csv_file_name: csv_name,
    };
    setIsLoading(true);
    const data = await CSVFilesSDK.deleteCSVs(body).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    let updateCSVSubscriber = API.graphql(
      graphqlOperation(onUpdateCsvDetail, { school_id: accountID })
    ).subscribe({
      next: (_) => {
        getCSVs();
      },
      error: (e) => {
        console.log(e);
        setIsLoading(false);
      },
    });
    let createCSVSubscriber = API.graphql(
      graphqlOperation(onCreateCsvDetail, { school_id: accountID })
    ).subscribe({
      next: (_) => {
        getCSVs();
      },
      error: (e) => {
        console.log(e);
        setIsLoading(false);
      },
    });
    let deleteCSVSubscriber = API.graphql(
      graphqlOperation(onDeleteCsvDetail, { school_id: accountID })
    ).subscribe({
      next: (_) => {
        getCSVs();
      },
      error: (e) => {
        console.log(e);
        setIsLoading(false);
      },
    });
    getCSVs();

    return () => {
      updateCSVSubscriber.unsubscribe();
      createCSVSubscriber.unsubscribe();
      deleteCSVSubscriber.unsubscribe();
    };
  }, [accountID, getCSVs]);

  return (
    <>
      {isLoading ? <div className="loading"></div> : null}
      <Row className="flex-col gap-3">
        {filePreviewTable ? (
          <FilePreviewContainer
            setFilePreviewTable={setFilePreviewTable}
            filePreviewTableColData={filePreviewTableColData}
            filePreviewTableData={filePreviewTableData}
          />
        ) : (
          <>
            <Table
              columns={CSVCols}
              dataSource={CSVDatasource}
              pagination={false}
              scroll={{
                x: 1000,
              }}
              locale={{
                emptyText: isLoading ? <SkeletonTable /> : <Empty />,
              }}
            />
          </>
        )}
      </Row>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default CSVFiles;
